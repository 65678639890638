import request from '@/utils/http'
export let GetIndex = (data) => request({
    url: '/index',
    method: 'GET',
    params: data
});


export let GetBannerLink = (data) => request({
    url: '/banner',
    method: 'GET',
    params: data
});

export let GetGoods = (data) => request({
    url: '/goods',
    method: 'GET',
    params: data
});

export let Getcaptcha = (data) => request({
    url: '/captcha?' + Math.random(),
    method: 'GET',
    params: data
});
export let CheckCaptchaApi = (data) => request({
    url: '/checkcaptcha',
    method: 'POST',
    params: data
});

export let login = (data) => request({
    url: '/login',
    method: 'POST',
    params: data
});
export let login2 = (data) => request({
    url: '/login2',
    method: 'POST',
    params: data
});
export let regAccont = (data) => request({
    url: '/reg',
    method: 'POST',
    params: data
});
export let sms = (data) => request({
    url: '/sms',
    method: 'POST',
    params: data
});
export let article = (data) => request({
    url: '/article',
    method: 'get',
    params: data
});
export let articleList = (data) => request({
    url: '/articlelist',
    method: 'get',
    params: data
});
export let xiaoka = (data) => request({
    url: '/xiaoka',
    method: 'post',
    params: data
});
export let getCertifyUrl = (data) => request({
    url: '/alicertfy',
    method: 'post',
    params: data
});
export let checkCertifyUrl = (data) => request({
    url: '/checkcertfy',
    method: 'get',
    params: data
});
export let checkCertifyUrl2 = (data) => request({
    url: '/checkcertfy2',
    method: 'get',
    params: data
});
export let my = (data) => request({
    url: '/my',
    method: 'get',
    params: data
});

export let moneyLog = (data) => request({
    url: '/moneylog',
    method: 'get',
    params: data
});

export let userGoodsList = (data) => request({
    url: '/user_goods_list',
    method: 'get',
    params: data
});
export let orderLog = (data) => request({
    url: '/orderlog',
    method: 'get',
    params: data
});
export let orderStatis = (data) => request({
    url: '/orderlog_statis',
    method: 'get',
    params: data
});

export let orderList = (data) => request({
    url: '/orderlist',
    method: 'get',
    params: data
});


export let txLog = (data) => request({
    url: '/txlog',
    method: 'get',
    params: data
});

export let freezeLog = (data) => request({
    url: '/freezelog',
    method: 'get',
    params: data
});

export let tx = (data) => request({
    url: '/tx',
    method: 'post',
    params: data
});
export let txaccount = (data) => request({
    url: '/txaccount',
    method: 'get',
    params: data
});
export let addtxaccount = (data) => request({
    url: '/addtxaccount',
    method: 'post',
    params: data
});
export let GetCode = (data) => request({
    url: '/getcode',
    method: 'get',
    params: data
});


export let getCertifyUrl2 = (data) => request({
    url: '/alicertfy2',
    method: 'post',
    params: data
});
export let getConfig = (data) => request({
    url: '/config',
    method: 'get',
    params: data
});
export let checkLogin = (data) => request({
    url: '/checklogin',
    method: 'get',
    params: data
});

export let editPwd = (data) => request({
    url: '/editpwd',
    method: 'post',
    params: data
});
export let EditPayPwd = (data) => request({
    url: '/editpaypwd',
    method: 'post',
    params: data
});
